import { getAccessToken, getBrandUserId } from '../utilities/storage';

export const setHeaders = (headers = {}) => ({
  'Content-Type': 'application/json',
  'Client-Code': 'bulbasaur',
  'Accept-Language': 'en',
  'x-access-token': getAccessToken() || '',
  ...headers,
});

// export const setAuthTokenHeader = () => (
//   getToken() ? {
//     Authorization: `Token ${getToken()}`,
//   } : {});

export const setAuthorizationHeaders = () => (
  getBrandUserId() ? {
    'brand-user-id': getBrandUserId() || '',
  } : {});

export const getParams = (parameters) => {
  const searchParams = new URLSearchParams();
  const params = Object.entries(parameters);
  params.forEach((param) => {
    if (
      (param[1] || param[1] === 0)
          && param[1] !== 'null'
          && param[1] !== 'undefined'
    ) {
      searchParams.append(...param);
    }
  });
  return searchParams;
};

export const apiUrls = () => {
//   let legacyBaseUrl = 'https://apionlinedelivery-staging.mpaani.com/';
//   let OOBaseUrl = 'https://apionlinedelivery-staging.mpaani.com/';
  let newOOBaseUrl = 'https://homedeliverybackend-staging.mpaani.com/';
  //   let imageBaseUrl = 'https://saggregation.mpaani.com';

  if (process.env.REACT_APP_ENV === 'production') {
    // legacyBaseUrl = 'https://api.mpaani.com/';
    // OOBaseUrl = 'https://apionlinedelivery.mpaani.com/';
    newOOBaseUrl = 'https://homedeliverybackend.mpaani.com/';
    // imageBaseUrl = 'https://apianalytics.mpaani.com';
  }

  if (process.env.REACT_APP_ENV === 'release') {
    // legacyBaseUrl = 'https://api.mpaani.com/';
    // OOBaseUrl = 'https://apionlinedelivery.mpaani.com/';
    newOOBaseUrl = 'https://homedeliverybackend-release.mpaani.com/';
    // imageBaseUrl = 'https://apianalytics.mpaani.com';
  }

  return {
    // legacyBaseUrl,
    // OOBaseUrl,
    newOOBaseUrl,
    // imageBaseUrl,
  };
};
