const { localStorage } = window;

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export function setRefreshToken(token) {
  localStorage.setItem('refresh_token', token);
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function setAccessToken(token) {
  localStorage.setItem('access_token', token);
}

export function setBrandId(uuid) {
  localStorage.setItem('brandId', uuid);
}

export function getBrandId() {
  return localStorage.getItem('brandId');
}

export function setBrandUserId(uuid) {
  localStorage.setItem('brandUserId', uuid);
}

export function getBrandUserId() {
  return localStorage.getItem('brandUserId');
}

export function setBrands(brands) {
  return localStorage.setItem('brands', JSON.stringify(brands));
}

export function getBrands() {
  return JSON.parse(localStorage.getItem('brands'));
}

export function setBrandUser(brandsUser) {
  return localStorage.setItem('brandsUser', JSON.stringify(brandsUser));
}

export function getBrandUser() {
  return JSON.parse(localStorage.getItem('brandsUser'));
}

export function getLastAccessTokenUpdatedAtEpoch() {
  return JSON.parse(localStorage.getItem('mp_access_token_updated_at'));
}

export function setLastAccessTokenUpdatedAtEpoch(lastAccessTokenUpdatedAt) {
  localStorage.setItem('mp_access_token_updated_at', JSON.stringify(lastAccessTokenUpdatedAt));
}

export function removeAuthentication() {
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('brandId');
  localStorage.removeItem('brandUserId');
  localStorage.removeItem('brands');
  localStorage.removeItem('brandsUser');
}
